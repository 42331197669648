




















































import { Component } from 'vue-property-decorator';
import InlineSvg from 'vue-inline-svg';
import styleVariables from '../../styles/export.scss';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';
import eyeOpen from '../../assets/password_eye_open.svg';
import eyeClosed from '../../assets/password_eye_closed.svg';

@Component({
  name: 'BasePasswordInput',
  components: {
    BaseControlWrapper,
    InlineSvg
  }
})
export default class BasePasswordInput extends BaseFormControl {
  hide = true;

  icons = {
    passwordEyeOpen: eyeOpen,
    passwordEyeClosed: eyeClosed
  };

  styleVariables = styleVariables;

  created(): void {
    super.created();
  }
}
