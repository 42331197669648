





















































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import LoginForm from '@improve/common-components/src/components/forms/LoginForm.vue';
import BaseCenteredCard
  from '@improve/common-components/src/components/widgets/BaseCenteredCard.vue';
import TermsAndConditionsForm
  from '@improve/common-components/src/components/forms/TermsAndConditionsForm.vue';
import Organization from '@improve/common-utils/src/model/Organization';
import UserAuthObject from '@improve/common-utils/src/model/UserAuthObject';
import { userIsAdmin } from '@improve/common-utils/src/services/SecurityService';
import User from '@improve/common-utils/src/model/User';
import UIStates from '@improve/common-utils/src/types/UIStates';
import SSO_MODE from '@improve/common-utils/src/model/SsoMode';

@Component({
  name: 'Login',
  components: {
    LoginForm,
    BaseButton,
    TermsAndConditionsForm,
    BaseCenteredCard
  }
})
export default class Login extends Vue {
  @Action login!: ActionMethod;

  @Action logout!: ActionMethod;

  @Action fetchOrgSsoMode!: ActionMethod;

  @Action fetchCurrentUser!: ActionMethod;

  @Action acceptTermsAndConditions!: ActionMethod;

  @Getter currentUser!: User;

  @Getter currentAuth!: UserAuthObject;

  @Getter currentOrganization!: Organization;

  @Getter isAuthenticated!: boolean;

  loginAllowList: string[] = process.env.VUE_APP_LOGIN_ALLOW_LIST?.split(',') || [];

  uiStates = UIStates;

  currentUIState: string | null = UIStates.SUBMIT_CREDENTIALS;

  tcDocLinks: { ppLink: string | null; tcLink: string | null } | null = null;

  showForm = false;

  email = '';

  password = '';

  customError = '';

  errorHappened = false;

  token = '';

  get formTitle(): string {
    return this.$t(this.isAdminLogin
      ? 'common.adminLogin'
      : 'common.login')
      .toString();
  }

  get loginError(): string {
    if (this.customError) {
      return this.customError;
    }
    return this.$t(this.isAdminLogin
      ? 'error.incorrectEmailPassOrNotAdmin'
      : 'error.incorrectEmailPass')
      .toString();
  }

  get isAdminLogin(): boolean {
    return !!this.$route.query.isAdmin;
  }

  created(): void {
    this.checkUserTC(this.isAuthenticated);
  }

  async loginSubmit(event: { email: string; password: string }): Promise<void> {
    if (!(await this.validateAndSubmit())) {
      return;
    }
    await this.login({
      email: event.email,
      password: event.password,
      isAdmin: this.isAdminLogin
    })
      .then((userAuth: UserAuthObject) => {
        this.token = userAuth.token;
        this.checkUserTC(true);
      })
      .catch((error) => {
        this.errorHappened = true;
        this.customError = this.$t(error.message).toString();
      });
  }

  isAllowedHost(url: string): boolean {
    return this.loginAllowList.some((host) => url.startsWith(host));
  }

  redirect(): void {
    const redirect: string = this.$route.query.redirect as string;
    if (this.isAdminLogin && !userIsAdmin(this.currentAuth)) {
      this.logout();
    } else if (redirect && this.isAllowedHost(redirect)) {
      window.location.href = `${redirect}?login-token=${this.currentAuth?.token || this.token}` as string;
    } else {
      this.gotoDashboard();
    }
  }

  checkUserTC(redirect = false): void {
    if (this.isAuthenticated && this.currentUser?.needToAcceptLatestTC(this.currentOrganization)) {
      this.showForm = true;
      this.currentUIState = UIStates.ACCEPT_TC;
    } else if (redirect) {
      this.redirect();
    } else {
      this.showForm = true;
    }
  }

  async acceptTC(): Promise<void> {
    await this.acceptTermsAndConditions();
    this.redirect();
  }

  gotoDashboard(): void {
    this.$router.push({ name: 'Dashboard' });
  }

  forgotPasswordSubmit(): void {
    this.$router.push({ name: 'ForgotPassword', params: { email: `${this.email}` } });
  }

  ssoSubmit(): void {
    this.$router.push({
      name: 'LoginSSO',
      params: {
        email: `${this.email}`
      },
      query: this.$route.query
    });
  }

  async validateAndSubmit(): Promise<boolean> {
    let ssoMode;
    try {
      ssoMode = await this.fetchOrgSsoMode(this.email);
    } catch (e) {
      this.$router.push({
        name: 'Register',
        params: {
          demo: 'true'
        }
      });
    }

    if (ssoMode === SSO_MODE.SAML_ONLY) {
      this.ssoSubmit();
      return Promise.resolve(false);
    }

    if (this.$refs.obs) {
      const comp: any = this.$refs.obs;
      return comp.validate();
    }
    this.customError = '';
    this.errorHappened = false;
    return Promise.resolve(false);
  }
}
