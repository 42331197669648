


































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BaseTextInput from '../core/BaseTextInput.vue';
import BasePasswordInput from '../core/BasePasswordInput.vue';
import BaseButton from '../widgets/BaseButton.vue';

@Component({
  name: 'LoginForm',
  components: {
    BaseTextInput,
    BasePasswordInput,
    BaseButton
  }
})
export default class LoginForm extends Vue {
  email: string | null = null;

  password: string | null = null;

  loginSubmit(): void {
    this.$emit('loginSubmit', {
      email: this.email,
      password: this.password
    });
  }

  forgotPassword(): void {
    this.$emit('forgotPassword', {
      email: this.email
    });
  }
}
